/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode } from 'react';
import { Paths } from '../../../../types/core';

export type DatagridObject = {
  _id?: string;
  [key: string]: any;
};

export type PaginationState = {
  offset: number;
  limit: number;
};

export type DatagridPaginationProps = {
  pagination: true;
  paginationState: PaginationState;
  onPaginationChange: (paginationState: PaginationState) => void;
  pageSizeOptions?: number[];
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortState<Sort> = {
  field: Sort;
  direction: SortDirection;
};

export type DatagridSortProps<Sort> = {
  sortable: true;
  sortState: SortState<Sort>;
  onSortChange: (sortState: SortState<Sort>) => void;
};

export type DatagridExpandableProps<Data extends DatagridObject> = {
  expandComponent?: FC<{ row: Data }>;
  expandMultiple?: boolean;
  canExpand?: (row: Data) => boolean;
};

export type DatagridFilterFormProps<FilterState> = {
  filterState: FilterState;
  onFilterChange: (filterState: FilterState) => void;
};

export type DatagridFilterProps<FilterInput> = {
  filterable: true;
  filterForm: FC<DatagridFilterFormProps<FilterInput>>;
} & DatagridFilterFormProps<FilterInput>;

export type DatagridHeaderColumn<Sort> = {
  header: string | React.ReactNode;
  width?: string;
  ghostLoader?: React.ReactNode;
} & (
  | {
      sortable?: false;
    }
  | {
      sortable: true;
      sortKey: Sort;
    }
);

export type DatagridColumn<
  Data extends DatagridObject = { _id: string; [key: string]: unknown },
  Sort = string,
> = DatagridHeaderColumn<Sort> & {
  accessor: Paths<Data> | ((item: Data, idx: number) => React.ReactNode);
};

export type DatagridPagerProps = Omit<DatagridPaginationProps, 'pagination'> & {
  totalRecords: number;
  pageSizeOptions?: number[];
};

export type DatagridTableHeadProps<Data extends DatagridObject, Sort> = {
  columns: DatagridColumn<Data, Sort>[];
  expandable: boolean;
} & ({ sortable?: false } | DatagridSortProps<Sort>) &
  ({ enableSelectAll?: false } | { enableSelectAll: true; onSelectAll: (checked: boolean) => void });

export type DatagridTableBodyProps<Data extends DatagridObject, Sort> = {
  loading: boolean;
  noDataText?: string | ReactNode;
  loadingText?: string;
  idColumn: keyof Data;
  onClickRow?: (item: Data) => void;
  data: Data[];
  columns: DatagridColumn<Data, Sort>[];
  ghostLoad?: boolean;
} & DatagridExpandableProps<Data>;

export type DatagridHeadProps<Filter = any> = {
  onSearchChange?: (search: string | undefined) => void;
  actions?: ReactNode | ReactNode[];
} & ({ filterable?: false } | DatagridFilterProps<Filter>);

export type BulkActionGenerator<Data extends DatagridObject> = (
  selected: Data['_id'][],
  clearSelected: () => void,
) => ReactNode | ReactNode[];

export type DatagridProps<Data extends DatagridObject, Sort, Filter = any> = {
  className?: string;
  testId?: string;
  idColumn: keyof Data;
  onClickRow?: (item: Data) => void;
  data: Data[];
  loading: boolean;
  noDataText?: string | ReactNode;
  loadingText?: string;
  totalRecords: number;
  columns: DatagridColumn<Data, Sort>[];
  headComponent?: React.ComponentType<DatagridHeadProps>;
  actions?: ReactNode | ReactNode[];
  bulkActions?: BulkActionGenerator<Data>;
  isSelectable?: (data: Data) => boolean;
  onSelectedChange?: (selected: Data['_id'][]) => void;
  showTableHead?: boolean;
  tableHeadComponent?: React.ComponentType<DatagridTableHeadProps<Data, Sort>> | false;
  tableBodyComponent?: React.ComponentType<DatagridTableBodyProps<Data, Sort>>;
  pagerComponent?: React.ComponentType<DatagridPagerProps>;
  ghostLoad?: boolean;
} & ({ pagination?: false } | DatagridPaginationProps) &
  ({ sortable?: false } | DatagridSortProps<Sort>) &
  DatagridExpandableProps<Data> &
  DatagridHeadProps<Filter>;
